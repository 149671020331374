import React, { useEffect, createRef } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import lottie from "lottie-web"
import StylisedText from "../components/elements/stylised-text"
import ButtonLink from "../components/elements/button-link"

import animation from "../../static/lottie/success.json"
import backgroundImg from '@/images/error_page_bg.webp'

const isBrowser = typeof window !== "undefined"

const ThankYouPage = ({ data }) => {

    let currentUrl = isBrowser ? window.location.pathname : "/";

    let animationContainer = createRef();
    const {
        //strapiGlobal,
        site: {
            siteMetadata: {
                languages: { locales, defaultLocale },
            },
        },
    } = data

    const global = data.strapiGlobal
    const navbar = data.strapiNavbar
    const footer = data.strapiFooter

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation,
        })
        return () => anim.destroy() // optional clean up for unmounting
    }, [animationContainer])

    return (
        <>
            <Seo seo={{ title: "Thank you" }} global={global} />
            <Layout
                navbar={navbar}
                footer={footer}
                pageContext={{
                    locale: defaultLocale,
                    locales,
                    defaultLocale,
                    slug: "thank-you",
                }}
            >
                <div className="thankyou" style={{ backgroundImage: `url(${backgroundImg})` }}>
                    <div className="thankyou__inner">
                        <div
                            className="thankyou__inner__animation-container"
                            ref={animationContainer}
                        />
                        <div className="thankyou__inner__title">
                            <StylisedText
                                data={[
                                    {
                                        id: 68,
                                        text: "THANK ",
                                        style: "bold",
                                        colour: "green",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "YOU!",
                                        style: "bold",
                                        colour: "orange",
                                        colourOverride: null,
                                    },
                                ]}
                                type="h5"
                            />

<br></br>
                            <span className="thankyou__inner__text space">
                                (We will get back to you soon 
                                <span className="thankyou__inner__text__emoji"> 😁</span>)
                            </span>
                        </div>

                        <div className="thankyou__inner__buttons">
                            <div>
                                <ButtonLink
                                    button={{
                                        newTab: "false",
                                        text: " Take to Home Scottie! 👽  ",
                                        type: "tertiary",
                                        url: "/",
                                        icon: "false"
                                    }}
                                    appearance="tertiary"
                                    key="1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ThankYouPage

export const query = graphql`
  query ThankYouQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      ...GlobalData
    }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
  }
`
